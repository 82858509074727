<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
	<app-header-mobile></app-header-mobile>

	<div class="d-flex flex-column flex-root">
		<div class="d-flex flex-row flex-column-fluid page">
			<ng-container *ngIf="asideSelfDisplay">
				<app-aside-left></app-aside-left> <!-- menu -->
			</ng-container>

      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
				<app-header></app-header> <!-- cabeçalho -->

        <div class="content d-flex flex-column flex-column-fluid" id="kt_content" [ngClass]="contentClasses">
					<ng-container *ngIf="subheaderDisplay">
						<app-subheader></app-subheader>
					</ng-container>

          <div ktContentAnimate class="d-flex flex-column-fluid">
						<div [ngClass]="contentContainerClasses">
							<router-outlet></router-outlet>
						</div>
					</div>

				</div>

				<app-footer></app-footer> <!-- rodapé  -->
			</div>
		</div>

		<app-quick-user-panel></app-quick-user-panel> <!-- informações usuário lateral direita -->
		<app-scroll-top></app-scroll-top> <!-- voltar ao topo da página -->
		<!-- <app-sticky-toolbar></app-sticky-toolbar> -->
	</div>
</ng-container>

<ng-template #blankLayout>
	<div class="d-flex flex-column flex-root">
		<router-outlet></router-outlet>
	</div>
</ng-template>
