import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { Retorno } from 'src/app/core/models/retorno'
import { DadosEventos } from '../shared/dados-evento.model'
import { EventosEsocialService } from '../shared/eventos-esocial.service'

@Component({
  selector: 'app-dados-eventos',
  templateUrl: './dados-eventos.component.html',
  styleUrls: ['./dados-eventos.component.scss']
})
export class DadosEventosComponent implements OnInit {
  dados: DadosEventos
  situacoes: any[] = [
    "Situação desconhecida", "Pendente de envio", "Enviado com erro", "Pendente de consulta",
    "Retornou com erro", "Enviado com sucesso", "Pendente de envio manual", "Schema inválido"
  ]
  eventoId: number = 0

  constructor(
    private service: EventosEsocialService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.eventoId = this.route.snapshot.params.eventoId

    this.service.pegarEventosPorId(this.eventoId).subscribe((retorno: Retorno<any>) => {
      this.dados = retorno.retorno
      this.cd.markForCheck()
    })
  }

}
