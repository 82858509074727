<div class="card">
  <div class="card-header">
    <div class="container">
      <span style="font-size: large;">Dados do Evento</span>
    </div>
  </div>
  <div class="card-body">
    <div class="form-row" *ngIf="dados !== undefined">
      <div class="form-group col-md-1 m-0 border rounded">
        <label><strong>ID</strong></label>
        <span class="form-control m-0 noborder">{{ dados.id }}</span>
      </div>
      <div class="form-group col-md-4 m-0 border rounded">
        <label><strong>Evento ID</strong></label>
        <span class="form-control m-0 noborder">{{ dados.idEvento }}</span>
      </div>
      <div class="form-group col-md-1 m-0 border rounded">
        <label><strong>Tipo</strong></label>
        <span class="form-control m-0 noborder">{{ dados.tipoEvento }}</span>
      </div>
      <div class="form-group col-md-2 m-0 border rounded">
        <label><strong>Descrição</strong></label>
        <span class="form-control m-0 noborder text-truncate">{{ dados.descricao }}</span>
      </div>
      <div class="form-group col-md-3 m-0 border rounded">
        <label><strong>Retorno</strong></label>
        <span class="form-control m-0 noborder">{{ situacoes[dados.situacao] }}</span>
      </div>
      <div class="form-group col-md-1 m-0 border rounded">
        <label><strong>Data</strong></label>
        <span class="form-control m-0 noborder">
            {{ dados.dataCompetencia ? (dados.dataCompetencia | date:'MM/yyyy') : '' }}
        </span>
      </div>
    </div>
    <div class="form-row" *ngIf="dados !== undefined">
      <div class="form-group col-md-12 my-3">
        <label><strong>Dados Protocolo Lote</strong></label>
      </div>
      <div class="form-group col-md-2 m-0 border rounded">
        <label><strong>Data Envio</strong></label>
        <span class="form-control m-0 noborder">{{ dados.protocoloLote.dataEnvio | date:'shortDate' }}</span>
      </div>
      <div class="form-group col-md-2 m-0 border rounded">
        <label><strong>Data Recepção</strong></label>
        <span class="form-control m-0 noborder">{{ dados.protocoloLote.dataRecepcao | date:'shortDate' }}</span>
      </div>
      <div class="form-group col-md-1 m-0 border rounded">
        <label><strong>Tipo Evento</strong></label>
        <span class="form-control m-0 noborder">{{ dados.protocoloLote.tipoEvento }}</span>
      </div>
      <div class="form-group col-md-4 m-0 border rounded">
        <label><strong>Protocolo Envio</strong></label>
        <span class="form-control m-0 noborder">{{ dados.protocoloLote.protocoloEnvio }}</span>
      </div>
      <div class="form-group col-md-1 m-0 border rounded">
        <label><strong>XML</strong></label>
        <span class="form-control m-0 noborder text-center">
          <a href="{{ dados.xml }}" target="_blank">
            <i class="flaticon-file text-digibyte" tooltip="Baixar xml de envio."></i>
          </a>
        </span>
      </div>
      <div class="form-group col-md-1 m-0 border rounded">
        <label><strong>XMLEnvio</strong></label>
        <span class="form-control m-0 noborder text-center">
          <a href="{{ dados.protocoloLote.xml }}" target="_blank">
            <i class="flaticon-file text-digibyte" tooltip="Baixar xml de envio."></i>
          </a>
        </span>
      </div>
      <div class="form-group col-md-1 m-0 border rounded">
        <label><strong>XMLConsulta</strong></label>
        <span class="form-control m-0 noborder text-center">
          <a href="{{ dados.protocoloLote.xmlConsulta }}" target="_blank">
            <i class="flaticon-file text-digibyte" tooltip="Baixar xml de envio."></i>
          </a>
        </span>
      </div>
    </div>
    <div class="form-row" *ngIf="dados !== undefined">
      <div class="form-group col-md-12 my-3">
        <label><strong>Dados Protocolos de Erros</strong></label>
      </div>
      <div class="form-group col-md-12 m-0 border rounded protocolo">
        <label><strong>Protocolo Erros</strong></label>
        <div *ngFor="let item of dados.protocoloErros">
          <i class="flaticon-information text-digibyte"></i> {{ item.codigo }} - {{ item.descricao }}
        </div>
      </div>
    </div>
    <div class="form-row" *ngIf="dados !== undefined">
      <div class="form-group col-md-2 m-0 border rounded">
        <label><strong>Status</strong></label>
        <span class="form-control m-0 noborder">{{ dados.protocoloLote.status }}</span>
      </div>
      <div class="form-group col-md-2 m-0 border rounded">
        <label><strong>Resposta Envio</strong></label>
        <span class="form-control m-0 noborder">{{ dados.protocoloLote.cdRespostaEnvio }}</span>
      </div>
      <div class="form-group col-md-2 m-0 border rounded">
        <label><strong>Resposta Consulta</strong></label>
        <span class="form-control m-0 noborder">{{ dados.protocoloLote.cdRespostaConsulta }}</span>
      </div>
    </div>
  </div>
</div>
