import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { rotas } from './constantes'
import { Esocialv2Component } from './views/pages/folha/esocialv2/esocialv2.component'
import { AutenticacaoGuard } from './core/guard/autenticacao.guard'
import { CompartilhadaGuard } from './core/guard/compartilhada.guard'
import { ContabilFiscalGuard } from './core/guard/contabil-fiscal.guard'
import { ContabilidadeGuard } from './core/guard/contabilidade.guard'
import { FiscalGuard } from './core/guard/fiscal.guard'
import { FolhaFiscalGuard } from './core/guard/folha-fiscal.guard'
import { FolhaGuard } from './core/guard/folha.guard'
import { GerenciadorGuard } from './core/guard/gerenciador.guard'
import { BaseComponent } from './views/theme/base/base.component'
import { BaseESocialComponent } from './views/theme/base-e-social/base-e-social.component'
import { DadosEventosComponent } from './views/pages/folha/eventos-esocial/dados-eventos/dados-eventos.component'


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./views/pages/autenticacao/autenticacao.module').then(m => m.AutenticacaoModule)
  },
  // {
  //   path: 'esocialv2',
  //   component: Esocialv2Component,
  // },
  {
    path: rotas.eventosEsocial2,
    // component: BaseESocialComponent,
    canActivate: [FolhaGuard],
    children: [
      { path: '',
        component: Esocialv2Component, data: { title: 'Eventos Esocial' }},
      { path: 'evento/:eventoId',
        component: DadosEventosComponent, data: {title: 'Dados Evento'}}
    ]
  },
  {
    path: rotas.relatoriosImpressos,
    canActivate: [AutenticacaoGuard],
    loadChildren: () => import('./views/pages/relatorios-impressos/relatorios-impressos.module').then(m => m.RelatoriosImpressosModule)
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AutenticacaoGuard],
    children: [
      {
        path: '',
        canActivate: [ContabilidadeGuard],
        loadChildren: () => import('./views/pages/contabilidade/contabilidade.module').then(m => m.ContabilidadeModule)
      },

      {
        path: '',
        canActivate: [FiscalGuard],
        loadChildren: () => import('./views/pages/fiscal/fiscal.module').then(m => m.FiscalModule)
      },
      {
        path: '',
        canActivate: [FolhaGuard],
        loadChildren: () => import('./views/pages/folha/folha.module').then(m => m.FolhaModule)
      },
      {
        path: '',
        canActivate: [ContabilFiscalGuard],
        loadChildren: () => import('./views/pages/contabil-fiscal/contabil-fiscal.module').then(m => m.ContabilFiscalModule)
      },
      {
        path: '',
        canActivate: [FolhaFiscalGuard],
        loadChildren: () => import('./views/pages/folha-fiscal/folha-fiscal.module').then(m => m.FolhaFiscalModule)
      },
      {
        path: '',
        canActivate: [CompartilhadaGuard],
        loadChildren: () => import('./views/pages/compartilhada/compartilhada.module').then(m => m.CompartilhadaModule)
      },
      {
        path: '',
        canActivate: [GerenciadorGuard],
        loadChildren: () => import('./views/pages/gerenciador/gerenciador.module').then(m => m.GerenciadorModule)
      },
      { path: rotas.acessoNegado, loadChildren: () => import('./views/pages/acesso-negado/acesso-negado.module').then(m => m.AcessoNegadoModule)},
      { path: rotas.dashboard, loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)},
      { path: rotas.notaLancamento, loadChildren: () => import('./views/pages/nota-lancamento/nota-lancamento.module').then(m => m.NotaLancamentoModule)},
      { path: rotas.relatorios, loadChildren: () => import('./views/pages/relatorios/relatorios.module').then(m => m.RelatoriosModule)},
      { path: '', redirectTo: rotas.dashboard, pathMatch: 'full' },
      { path: '**', redirectTo: rotas.dashboard, pathMatch: 'full' }
    ],
  },
  {path: '**', redirectTo: 'error/403', pathMatch: 'full'}
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload'}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
