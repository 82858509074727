// Angular
import { RouterModule } from '@angular/router'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgApexchartsModule } from 'ng-apexcharts'
// Perfect Scrollbar
//import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'
// Core module
import { CoreModule } from 'src/app/core/core.module'
// CRUD Partials
// Layout partials
import {
  LanguageSelectorComponent,
  QuickUserPanelComponent,
  ScrollTopComponent,
  SearchDefaultComponent,
  SearchResultComponent,
  SistemasComponent,
  SplashScreenComponent,
  StickyToolbarComponent,
  UserProfile2Component,
  UserProfile3Component,
  UserProfileComponent,
  UserProfile4Component
} from './layout'


import { ChatComponent } from './layout/topbar/chat/chat.component'
import { NotasLancamentoComponent } from './layout/topbar/notas-lancamento/notas-lancamento.component'
import { TermoComponent } from './layout/termo/termo.component'
import { RelatorioRapidoComponent } from './layout/topbar/relatorio-rapido/relatorio-rapido.component'
// General
import { NoticeComponent } from './content/general/notice/notice.component'
import { PortletModule } from './content/general/portlet/portlet.module'
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg-2'
import { MatButtonModule } from '@angular/material/button'
import { MatMenuModule } from '@angular/material/menu'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatTableModule } from '@angular/material/table'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatIconModule } from '@angular/material/icon'
import { MatRadioModule } from '@angular/material/radio'
import { MatNativeDateModule } from '@angular/material/core'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatCardModule } from '@angular/material/card'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatDialogModule } from '@angular/material/dialog'
import { ConfiguracoesComponent } from './layout/topbar/configuracoes/configuracoes.component'

@NgModule({
    declarations: [
        ScrollTopComponent,
        NoticeComponent,
        // topbar components
        ChatComponent,
        ConfiguracoesComponent,
        QuickUserPanelComponent,
        ScrollTopComponent,
        SearchResultComponent,
        SistemasComponent,
        SplashScreenComponent,
        StickyToolbarComponent,
        LanguageSelectorComponent,
        NotasLancamentoComponent,
        TermoComponent,
        RelatorioRapidoComponent,
        SearchDefaultComponent,
        UserProfileComponent,
        UserProfile2Component,
        UserProfile3Component,
        UserProfile4Component
    ],
    exports: [
        PortletModule,
        ScrollTopComponent,
        NoticeComponent,
        // topbar components
        ChatComponent,
        ConfiguracoesComponent,
        QuickUserPanelComponent,
        ScrollTopComponent,
        SearchResultComponent,
        SistemasComponent,
        SplashScreenComponent,
        StickyToolbarComponent,
        UserProfileComponent,
        UserProfile2Component,
        UserProfile3Component,
        UserProfile4Component
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        //PerfectScrollbarModule,
        InlineSVGModule,
        CoreModule,
        PortletModule,
        NgApexchartsModule,
        // angular material modules
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        MatDialogModule,
    ]
})
export class PartialsModule {
}
